import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import MaquetteLogo from "../images/logo.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Maquette Development | Home" />
    <div className="container">
        <img src={MaquetteLogo} alt="Maquette Software Solutions logo" />
        <p>The website is currently under-construction</p>
        <p><a href="mailto:karimgawish.1@gmail.com">Contact us: karimgawish.1@gmail.com</a></p>
    </div>
  </Layout>
)

export default IndexPage
